<template>
  <div>
    <label for="editor">Duyuru İçeriği:</label>
    <quill-editor id="editor" v-model="content" :options="editorOption" @input="$emit('input', $event)">
      <div id="toolbar" slot="toolbar">
        <!-- Add a bold button -->
        <button class="ql-bold" />
        <button class="ql-italic" />
        <button class="ql-underline" />
        <button class="ql-align" />
        <button class="ql-link" />

        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="small" />
          <option selected />
          <option value="large" />
          <option value="huge" />
        </select>
      </div>
    </quill-editor>
  </div>
</template>

<script>
import { BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
    BCardCode,
    BCardText,
  },
  props: {
    value: { type: String },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder: "Duyuru İçeriği...",
      },
      content: null,
    };
  },
  watch: {
    value(item) {
      this.content = item;
    },
  },
  mounted() {
    this.content = this.value;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
