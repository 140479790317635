<template>
  <app-overlay>
    <searchable-table
      :items="announces"
      :fields="fields"
      hover
      @row-clicked="update"
    >
      <template #modalbutton>
        <app-button
          size="md"
          text="Yeni Duyuru"
          icon="PlusIcon"
          @click="$showAppSidebar('Üst Banner Ekle', AnnounceModal)"
        />
      </template>

      <!-- Statü -->
      <template #status="data">
        <span v-if="data.item.status === 'active'">
          <b-badge
            variant="success"
            class="p-50"
          >Aktif</b-badge>
        </span>
        <span v-else-if="data.item.status === 'active-priority'">
          <b-badge
            variant="success"
            class="p-50"
          >Aktif (Öncelikli)</b-badge>
        </span>
        <span
          v-else-if="data.item.status === 'inactive' && new Date(data.item.endDate) < new Date()"
          v-b-tooltip.hover.top="'Lütfen Tarih Aralığını Güncelleyin.'"
          style="font-size: 15px; cursor: pointer"
        >
          <b-badge
            variant="danger"
            class="badge p-50"
          >Pasif</b-badge>
        </span>
        <span v-else>
          <b-badge
            variant="warning"
            class="p-50"
          >Pasif</b-badge>
        </span>
      </template>

      <!-- Sayaç -->
      <template #countDown="data">
        <app-switch
          v-model="data.item.countDown"
          left-icon="CheckIcon"
          right-icon="XIcon"
          @change="updateAnnounceCountDown(data.item, $event)"
        />
      </template>

      <template #actions="data">
        <div class="d-flex gap-3">
          <app-icon-button
            size="17"
            variant="gradient-danger"
            icon="TrashIcon"
            @click="deleteAnnounce(data.item)"
          />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import AnnounceModal from './announceModal.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'name', label: 'Duyuru Adı' },
        { key: 'startDate', label: 'Başlangıç Tarihi', formatDateTime: true },
        { key: 'endDate', label: 'Bitiş Tarihi', formatDateTime: true },
        { key: 'countDown', label: 'Sayaç' },
        { key: 'status', label: 'Durum' },
        { key: 'actions', label: 'Eylemler' },
      ],
      AnnounceModal,
    }
  },
  computed: {
    announces() {
      return this.$store.getters.announces
    },
  },
  mounted() {
    this.$store.dispatch('getCampaignAnnounces')
  },
  methods: {
    update(item) {
      this.$showAppSidebar('Üst Banner Güncelle', AnnounceModal, { ...item })
    },
    updateAnnounceCountDown(data, countDown) {
      this.$store.dispatch('updateAnnounce', { id: data._id, data: { countDown } })
    },
    deleteAnnounce(item) {
      this.$confirm({ message: 'Kayıt Silinecek Emin misiniz?' }, () => {
        this.$store.dispatch('deleteAnnounce', item?._id)
      })
    },
  },
}
</script>

<style></style>
