<template>
  <div>
    <b-custom-tabs :tab-index="tabIndex" :tabs="tabs" lazy />
  </div>
</template>

<script>
import AnnounceList from "./announces/announceList.vue";
import campaignList from "./campaigns/list.vue";
import optionData from "./optionData/index.vue";
import prices from "./prices/list.vue";

export default {
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        { title: "Kampanyalar", component: campaignList, route: "web-actions-campaigns" },
        { title: "Birim Fiyatlar", component: prices, route: "web-actions-prices" },
        { title: "Üst Banner", component: AnnounceList, route: "web-actions-announces" },
        { title: "DB Giriş", component: optionData, route: "web-actions-option-data" },
        { title: "Kupon Kodu", component: "", route: "web-actions-coupons" },
      ],
    };
  },
};
</script>

<style></style>
