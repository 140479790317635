<template>
  <custom-collapse :collapses="collapses" lazy />
</template>

<script>
import sectors from "./sectors/sectors.vue";
import osgb from "./osgb/osgb.vue";
import osb from "./osb/osb.vue";

export default {
  data() {
    return {
      collapses: [
        { title: "Sektörler", component: sectors },
        { title: "OSGB", component: osgb },
        { title: "OSB veya KSS", component: osb },
      ],
    };
  },
};
</script>

<style></style>
