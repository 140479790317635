<template>
  <app-overlay>
    <searchable-table
      :items="$store.getters.webCampaigns"
      :fields="fields"
      hover
      @row-clicked="updateCampaign"
    >
      <template #modalbutton>
        <app-button
          text="Yeni Kampanya"
          icon="PlusIcon"
          size="md"
          @click="showSidebar"
        />
      </template>
      <template #status="{ item }">
        <app-switch
          v-model="item.status"
          left-icon="CheckIcon"
          right-icon="XIcon"
          @change="updateStatus(item._id, $event)"
        />
      </template>
      <template #actions="{ item }">
        <app-icon-button
          icon="TrashIcon"
          variant="gradient-danger"
          @click="deleteCampaign(item._id)"
        />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import campaignSidebar from './sidebar/sidebar.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'name', label: 'Kampanya Adı' },
        { key: 'startDate', label: 'Başlangıç Tarihi', formatDateTime: true },
        { key: 'endDate', label: 'Bitiş Tarihi', formatDateTime: true },
        { key: 'status', label: 'Durum' },
        { key: 'actions', label: 'Eylemler' },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('getWebCampaigns')
  },
  methods: {
    updateStatus(_id, status) {
      this.$store.dispatch('updateCampaignStatus', { _id, status })
    },
    updateCampaign(item) {
      this.$store.dispatch('getCampaignDetails', item?._id)
    },
    showSidebar() {
      this.$showAppSidebar('Kampanya Oluştur', campaignSidebar)
    },
    deleteCampaign(id) {
      this.$confirm({ message: 'Kampanyayı silmek istediğinize emin misiniz?' }, () => {
        this.$store.dispatch('deleteWebCampaign', id)
      })
    },
  },
}
</script>

<style></style>
