<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer>
      <app-input
        v-model="data.name"
        label="Duyuru Adı:"
        name="Duyuru Adı"
        placeholder="Duyuru Adı..."
        rules="required"
      />
      <div class="d-flex flex-column gap-10">
        <app-date-input
          v-model="data.startDate"
          name="Tarih"
          label="Tarih:"
          placeholder="Tarih Seçiniz..."
          rules="required"
          mode="range"
        />
        <!--  <app-date-input name="Bitiş Tarihi" v-model="data.endDate" label="Bitiş Tarihi:" placeholder="Tarih Seçiniz..." time rules="required" /> -->

        <editor-vue v-model="data.description" />
        <div class="d-flex justify-content-center gap-10 py-50">
          Sayaç
          <app-switch
            v-model="data.countDown"
            left-icon="CheckIcon"
            right-icon="XIcon"
          />
        </div>
        <!-- <verte v-model="color" :showHistory="null" picker="square" model="hex"></verte> -->
      </div>
      <hr>
      <!-- Butonlar -->
      <div class="d-flex justify-content-center gap-20">
        <b-form-radio
          v-model="data.status"
          value="active"
          class="custom-control-success"
          :disabled="new Date(data.endDate) < new Date() ? true : false"
        >
          Aktif
        </b-form-radio>
        <b-form-radio
          v-model="data.status"
          value="active-priority"
          class="custom-control-success"
          :disabled="new Date(data.endDate) < new Date() ? true : false"
        >
          Aktif (Öncelikli)
        </b-form-radio>
        <b-form-radio
          v-model="data.status"
          value="inactive"
          class="custom-control-primary"
        >
          Pasif
        </b-form-radio>
      </div>
      <hr>
      <div class="d-flex justify-content-around align-items-center">
        <app-button
          :text="updateItem ? 'Güncelle' : 'Kaydet'"
          variant="gradient-primary"
          size="md"
          block
          @click="updateItem ? updateCampaign() : setCampaign()"
        />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
// Validations
import { ValidationObserver } from 'vee-validate'
import editorVue from './editor.vue'

export default {
  components: {
    ValidationObserver,
    editorVue,
  },
  data() {
    return {
      data: {
        status: 'active',
        countDown: false,
      },
    }
  },
  computed: {
    updateItem() {
      return this.$store.getters.appSidebarItem
    },
  },
  mounted() {
    if (this.updateItem) {
      this.data = this.updateItem
    }
  },
  methods: {
    setData() {
      const data = {}
      const passFields = ['_id', 'createdAt', 'updatedAt']
      Object.keys(this.data).map(key => !passFields.includes(key) && (data[key] = this.data[key]))
      return data
    },
    setCampaign() {
      console.log(this.setData())
      /* this.$store.dispatch("newCampaignAnnounce", this.setData()); */
    },
    updateCampaign() {
      console.log(this.setData())
      /* this.$store.dispatch("updateAnnounce", {
        data: this.setData(),
        id: this.data?._id,
      }); */
    },
  },
}
</script>
