<template>
  <app-overlay>
    <db-searchable-table v-model="filters" :total-rows="totalRows" :items="list" :fields="fields" searchable class="border rounded" hover @changed="getOsgbs" @row-clicked="update">
      <template #modalbutton>
        <b-dropdown size="sm" text="Primary" variant="primary" no-caret split @click="$showAppSidebar('OSGB Ekle', osgbSidebar)">
          <template #button-content>
            <span class="d-flex align-items-center fs-14 gap-4 py-25">OSGB Ekle<feather-icon icon="PlusIcon" size="17" /></span>
          </template>
          <b-dropdown-item>
            <span class="d-flex align-items-center gap-10"><feather-icon icon="UploadIcon" size="13" /> İçe Aktar </span>
          </b-dropdown-item>
          <b-dropdown-item>
            <span class="d-flex align-items-center gap-10"><feather-icon icon="DownloadIcon" size="13" /> Dışa Aktar </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-3">
          <app-icon-button variant="gradient-danger" icon="TrashIcon" @click="deleteOsgb(item._id)" />
        </div>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import graphqlRequest from "@/utils/graphqlRequest";
import osgbSidebar from "./sidebar.vue";

export default {
  data() {
    return {
      totalRows: 0,
      filters: {},
      fields: [
        { key: "name", label: "OSGB Adı" },
        { key: "province.value", label: "İl", capitalize: true },
        { key: "address", label: "Adres" },
        { key: "webUrl", label: "Web Url" },
        { key: "actions", label: "Eylemler" },
      ],
      osgbSidebar,
    };
  },
  computed: {
    list() {
      return this.$store.getters?.osgbList;
    },
  },
  mounted() {
    this.getOsgbs();
  },
  methods: {
    getOsgbs() {
      graphqlRequest(
        "/optionData",
        {
          query: `
      {
        paginatedOsgbList(limit: ${this.filters.limit}, page: ${this.filters.page}, search:"${this.filters.search ? this.filters.search : ""}"){
          data{_id name address webUrl province{_id value}} totalCount
        }
      }
      `,
        },
        { loading: "table" }
      )
        .then(({ paginatedOsgbList }) => {
          const { data, totalCount } = paginatedOsgbList;
          this.$store.commit("setOsgbList", data);
          this.totalRows = totalCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteOsgb(id) {
      this.$confirm({ message: "OSGB Silinecek Emin misiniz?" }, () => {
        this.$axios
          .delete(`/option-data/osgb/${id}`, { loading: "table" })
          .then(() => {
            this.$store.commit("deleteOsgb", id);
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı" });
          })
          .catch((err) => {
            console.log(err);
          });
        /*  graphqlRequest(
          "/optionData",
          `
      mutation {
      deleteOsgb(_id: "${id}"){
        _id
        }
      }
      `
        )
          .then(({ deleteOsgb }) => {
            this.$store.commit("deleteOsgb", deleteOsgb._id);
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı" });
          })
          .catch((err) => {
            console.log(err);
          }); */
      });
    },
    update(item) {
      this.$showAppSidebar("Güncelle", osgbSidebar, item);
    },
  },
};
</script>

<style></style>

<!-- Adı İli Adresi web sayfası (excel şablonu) -->
