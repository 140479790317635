<template>
  <app-overlay>
    <searchable-table
      :items="items"
      :fields="fields"
    >
      <template
        v-for="(field, key) in riskStateFields"
        :slot="field.key"
        slot-scope="data"
      >
        <app-input
          :key="key"
          v-model.number="data.item.riskStatePrices[findItemIndex(data.item.riskStatePrices, field.label)].price"
          name="Birim Fiyat"
          texticon="₺"
        />
      </template>
      <template #actions="{ item }">
        <app-button
          text="Güncelle"
          icon="RefreshCwIcon"
          @click="updateItem(item.province._id, item.riskStatePrices)"
        />
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      riskStateFields: [
        { key: 'lowDanger', label: 'Az Tehlikeli' },
        { key: 'danger', label: 'Tehlikeli' },
        { key: 'highDanger', label: 'Çok Tehlikeli' },
        { key: 'dspPrice', label: 'DSP Farkı' },
      ],
    }
  },
  computed: {
    fields() {
      return [{
        key: 'province.value', label: 'İL', sortable: true, capitalize: true,
      }, ...this.riskStateFields, { key: 'actions', label: 'Eylemler' }]
    },
    findItemIndex() {
      return (items, label) => items.findIndex(item => item.label === label)
    },
  },
  mounted() {
    this.$axios
      .get('/settings/riskstate-prices', { loading: 'table' })
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    updateItem(province, items) {
      this.$axios
        .patch(`/settings/riskstate-prices/${province}`, { items }, { loading: 'table' })
        .then(response => {
          this.$emitter.$emit('Notification', {
            title: 'Başarılı',
            message: 'Fiyatlar başarıyla güncellendi.',
            variant: 'success',
          })
        })
        .catch(error => {
          this.$emitter.$emit('Notification', {
            title: 'Hata Oluştu',
            variant: 'danger',
          })
        })
    },
  },
}
</script>

<style></style>
