<template>
  <app-overlay>
    <searchable-table
      class="border rounded"
      :items="sectors"
      :fields="fields"
      hover
      @row-clicked="updateSector"
    >
      <template #modalbutton>
        <b-dropdown
          split
          class="flex-1-md"
          size="sm"
          variant="primary"
          no-caret
          @click="$showAppSidebar('Sektör Ekle', sectorForm)"
        >
          <template #button-content>
            <span class="d-flex align-items-center fs-14 gap-4 py-25">Sektör Ekle<feather-icon
              icon="PlusIcon"
              size="17"
            /></span>
          </template>
          <b-dropdown-item>
            <span class="d-flex align-items-center gap-10"><feather-icon
              icon="UploadIcon"
              size="13"
            /> Toplu Ekle </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-3">
          <app-icon-button
            variant="gradient-danger"
            icon="TrashIcon"
            size="16"
            @click="deleteSector(item._id)"
          />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import sectorForm from './sidebar.vue'

export default {
  components: { ValidationObserver },
  data() {
    return {
      fields: [
        {
          key: 'value', label: 'Sektör', sortable: true, capitalize: true,
        },
        { key: 'riskState', label: 'Tehlike Sınıfı', sortable: true },
        { key: 'actions', label: 'Eylemler', sortable: true },
      ],
      sectorForm,
    }
  },
  computed: {
    sectors() {
      return this.$store.getters.sectors
    },
  },
  mounted() {
    this.getSectors()
  },
  destroyed() {
    this.$store.commit('setSectors', [])
  },
  methods: {
    getSectors() {
      this.$store.dispatch('getSectors')
    },
    deleteSector(id) {
      this.$confirm({ message: 'Sektör Silinecek Emin misiniz?' }, () => {
        this.$axios
          .delete(`/option-data/sectors/${id}`, { loading: 'table' })
          .then(response => {
            this.$store.commit('deleteSector', id)
            this.$emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
          })
          .catch(error => {
            console.log(error)
            this.$emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu' })
          })
      })
    },
    updateSector(item) {
      this.$showAppSidebar('Sektör Güncelle', sectorForm, item)
    },
  },
}
</script>

<style>
.app-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.upper-button {
  cursor: pointer;
  transition: all 0.2s ease;
}
.upper-button:hover {
  transform: translateY(-2px);
}
</style>
