<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="osbform">
      <app-input
        v-model="data.name"
        name="Adı"
        label="Adı:"
        placeholder="Adı..."
        rules="required"
      />
      <app-select-input
        v-model="data.type"
        name="Tür"
        :options="options"
        label="Tür:"
        placeholder="Tür..."
        rules="required"
      />
      <provinces
        v-model="data.province"
        :reduce="(option) => option._id"
      />
      <counties
        v-model="data.county"
        :reduce="(option) => option._id"
        :province="data.province"
      />
      <app-input
        v-model="data.companyCount"
        name="İşletme Sayısı"
        type="number"
        label="İşletme Sayısı:"
        placeholder="İşletme Sayısı..."
      />
      <app-button
        :text="updateItem ? 'Güncelle' : 'Oluştur'"
        block
        @click="updateItem ? updateOsb() : newOsb()"
      />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {},
      options: ['OSB', 'KSS'],
    }
  },
  computed: {
    updateItem() {
      return this.$store.getters.appSidebarItem
    },
  },
  mounted() {
    if (this.updateItem) {
      this.data = { ...this.updateItem }
      this.data.province = this.data?.province?._id
      this.data.county = this.data?.county?._id
    }
  },
  methods: {
    newOsb() {
      this.$validate(this.$refs.osbform, () => {
        this.$store.dispatch('newOsb', this.data)
      })
    },
    updateOsb() {
      this.$validate(this.$refs.osbform, () => {
        this.$store.dispatch('updateOsb', { id: this.updateItem?._id, data: this.data })
      })
    },
  },
}
</script>

<style></style>
