<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="sectorform">
      <div class="app-form">
        <app-input
          v-model="sector"
          name="Sektör"
          label="Sektör:"
          placeholder="Sektör Giriniz..."
          rules="required"
        />
        <app-select-input
          v-model="riskState"
          name="Tehlike Sınıfı"
          label="Tehlike Sınıfı:"
          select_label="value"
          placeholder="Tehlike Sınıfı Seçiniz..."
          :options="riskStateOptions"
        />
        <app-button
          :text="updateItem ? 'Güncelle' : 'Oluştur'"
          block
          @click="updateItem ? updateSector() : createSector()"
        />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      sector: null,
      riskState: null,
      fields: [
        { key: 'value', label: 'Sektör', sortable: true },
        { key: 'riskState.value', label: 'Tehlike Sınıfı', sortable: true },
        { key: 'actions', label: 'Eylemler', sortable: true },
      ],
      riskStateOptions: [
        {
          id: '0',
          value: 'Az Tehlikeli',
        },
        {
          id: '1',
          value: 'Tehlikeli',
        },
        {
          id: '2',
          value: 'Çok Tehlikeli',
        },
      ],
    }
  },
  computed: {
    updateItem() {
      return this.$store.getters?.appSidebarItem
    },
  },
  mounted() {
    if (this.updateItem) {
      this.sector = this.updateItem?.value
      this.riskState = this.updateItem?.riskState
    }
  },
  methods: {
    createSector() {
      this.$validate(this.$refs.sectorform, () => {
        this.$axios
          .post('/option-data/sectors', { value: this.sector }, { loading: 'sidebar' })
          .then(response => {
            this.$store.commit('newSector', response.data)
            this.$store.commit('cleanAppSidebar')
            this.$emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
    updateSector() {
      this.$validate(this.$refs.sectorform, () => {
        this.$axios
          .patch(`/option-data/sectors/${this.updateItem._id}`, { value: this.sector }, { loading: 'sidebar' })
          .then(response => {
            this.$store.commit('updateSector', response.data)
            this.$store.commit('cleanAppSidebar')
            this.$emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
          })
          .catch(error => {
            console.log(error)
          })
      })
    },
  },
}
</script>

<style></style>
