<template>
  <app-overlay>
    <searchable-table
      class="border rounded"
      :items="list"
      :fields="fields"
      hover
      @row-clicked="updateOsb"
    >
      <template #modalbutton>
        <app-button
          text="Yeni Ekle"
          icon="PlusIcon"
          size="md"
          @click="$showAppSidebar('OSB / KSS Ekle', osbSidebar)"
        />
      </template>
      <template #actions="{ item }">
        <div class="d-flex gap-3">
          <app-icon-button
            variant="gradient-danger"
            icon="TrashIcon"
            @click="deleteOsb(item._id)"
          />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import graphqlRequest from '@/utils/graphqlRequest'
import osbSidebar from './sidebar.vue'

export default {
  data() {
    return {
      osbSidebar,
      fields: [
        { key: 'name', label: 'İsim', sortable: true },
        { key: 'type', label: 'Tür', sortable: true },
        {
          key: 'province.value', label: 'İl', sortable: true, capitalize: true,
        },
        {
          key: 'county.value', label: 'İlçe', sortable: true, capitalize: true,
        },
        { key: 'companyCount', label: 'İşletme Sayısı', sortable: true },
        { key: 'actions', label: 'Eylemler', sortable: false },
      ],
    }
  },
  computed: {
    list() {
      return this.$store.getters?.osbList
    },
  },
  mounted() {
    this.getOsbList()
  },
  methods: {
    getOsbList() {
      graphqlRequest(
        '/optionData',
        {
          query: `
      {
        OsbList{ _id name type province{_id value} county {_id value}  companyCount}
      }
      `,
        },
        { loading: 'table' },
      )
        .then(({ OsbList }) => {
          this.$store.commit('setOsbList', OsbList)
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateOsb(item) {
      this.$showAppSidebar('OSB / KSS Güncelle', osbSidebar, item)
    },
    deleteOsb(id) {
      this.$confirm({ message: 'OSB Silinecek Emin misiniz?' }, () => {
        this.$axios
          .delete(`/option-data/osb/${id}`, { loading: 'table' })
          .then(() => {
            this.$store.commit('deleteOsb', id)
            this.$emitter.$emit('Notification', { variant: 'success', title: 'İşlem Başarılı' })
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
